import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgGlobusIcon: React.FC<{ className?: string }> = ({ className }) => {

  return (
    <SvgIconContainer className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="11.9998" r="9" stroke="currentColor" strokeWidth="2" />
        <path
          d="M15.375 12C15.375 16.3492 12 19.875 12 19.875C12 19.875 8.625 16.3492 8.625 12C8.625 7.65073 12 4.12498 12 4.12498C12 4.12498 15.375 7.65073 15.375 12Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <line x1="4.125" y1="12.1248" x2="19.875" y2="12.1248" stroke="currentColor" strokeWidth="2" />
      </svg>
    </SvgIconContainer>
  );
};
