import { INotification } from 'reducers/notificationsReducer';

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const LOAD_MORE_NOTIFICATIONS = 'LOAD_MORE_NOTIFICATIONS';
export const GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS = 'GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS';
export const CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT = 'CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT';
export const SET_READED_NOTIFICATIONS = 'SET_READED_NOTIFICATIONS';

const getNotificationsSuccess = ({ count, next, results }: { count: number, next: null | string, results: INotification[] }) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: { count, next, list: results },
});

const getNotificationsStarted = () => ({ type: GET_NOTIFICATIONS_START });

const getNotificationsFailure = (error: string) => ({
  type: GET_NOTIFICATIONS_FAILURE,
  payload: { error },
});

const loadMoreNotifications = ({ count, next, results }: { count: number; next: null | string; results: INotification[] }) => ({
  type: LOAD_MORE_NOTIFICATIONS,
  payload: { count, next, list: results },
});

const getUnreadCountNotificationSuccess = (payload: { unread_notifications: number; unread_trade_message: number; }) => ({
  type: GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS,
  payload,
});

const changeNotificationsChatMessagesCount = (payload: { increment?: number; absolute?: number; }) => ({
  type: CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT,
  payload,
});

const setReadedNotifications = (payload: { ids?: number[]; unread_notifications: number; }) => ({
  type: SET_READED_NOTIFICATIONS,
  payload,
});


export {
  getNotificationsSuccess,
  getNotificationsStarted,
  getNotificationsFailure,
  loadMoreNotifications,
  getUnreadCountNotificationSuccess,
  changeNotificationsChatMessagesCount,
  setReadedNotifications,
};
