import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgUploadCubeIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <SvgIconContainer classes={className}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 13L16 17L24 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 26V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 12V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 9L16 6L19 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M9.53201 11L7 12.3718V22.1239L16 27L25 22.1239V12.3718L22.755 11.1555"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIconContainer>
  );
};
