import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgMarketIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 14V17L9 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M9.18013 14H15.0497C16.1543 14 17.0497 13.1046 17.0497 12V4C17.0497 2.89543 16.1543 2 15.0497 2H3C1.89543 2 1 2.89543 1 4V12C1 13.1046 1.89543 14 3 14H4"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6 8H12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIconContainer>
  );
};
