import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgFilterIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line
          x1="3"
          y1="7"
          x2="12"
          y2="7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="17"
          y1="7"
          x2="21"
          y2="7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="15" cy="7" r="2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <line
          x1="21"
          y1="17"
          x2="12"
          y2="17"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="7"
          y1="17"
          x2="3"
          y2="17"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="9"
          cy="17"
          r="2"
          transform="rotate(-180 9 17)"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIconContainer>
  );
};
