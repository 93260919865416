import { WritableDraft, Draft } from 'immer/dist/types/types-external';
import { ActionReducerMapBuilder, AsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { IAdditionalApiStatuses, IApiListResponse, IInitListState } from './interfaces';
import { AnyTODO } from 'core/interfaces';

export class FactoryBuilder<State extends IAdditionalApiStatuses> {

  constructor(public builder: ActionReducerMapBuilder<State>) {}

  buildAction<Returned, ThunkArg>(action: AsyncThunk<Returned, ThunkArg, {}>): this {
    this.builder
      .addCase(action.pending, state => { state.loading = true; })
      .addCase(action.rejected, (state, action) => {
        state.error = action.error?.message || '';
        state.loaded = false;
        state.loading = false;
      })
      .addCase(action.fulfilled, (state, action) => ({
        ...state,
        error: null,
        loading: false,
        loaded: true,
        ...action.payload,
      }));
    return this;
  }
}

export class FactoryListBuilder<T extends { id?: number }, State extends IInitListState<T>> extends FactoryBuilder<State> {
  buildListAction<ThunkArg extends { append?: boolean }>(action: AsyncThunk<IApiListResponse<T> | null, ThunkArg, {}>): this {
    this.builder
      .addCase(action.pending, (state, action) => {
        if (action.meta.arg.append) state.appending = true;
        else state.loading = true;
      })
      .addCase(action.rejected, (state, action) => {
        state.error = action.error?.message || '';
        state.loaded = false;
        state.loading = false;
        state.appending = false;
      })
      .addCase(action.fulfilled, (state, action) => {
        if (action.payload == null) {
          return {
            ...state,
            error: null,
            loaded: true,
            loading: false,
            appending: false,
          };
        }
        return {
          ...state,
          error: null,
          loaded: true,
          loading: false,
          appending: false,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          items: action.meta.arg.append ? [...state.items, ...action.payload.results] : action.payload.results,
          list: buildList(state.list, action.payload.results, action.meta.arg.append),
        };
      });
    return this;
  }
  buildListSetAction<ThunkArg extends { id?: number }>(action: AsyncThunk<T, ThunkArg, {}>): this {
    this.builder
      .addCase(action.pending, state => { state.loading = true; })
      .addCase(action.rejected, (state, action) => {
        state.error = action.error?.message || '';
        state.loaded = false;
        state.loading = false;
      })
      .addCase(action.fulfilled, (state, action) => {
        const payload = action.payload;
        const id = (payload.id as number) || action.meta.arg.id;

        if (!id) {
          return state;
        }
        return {
          ...state,
          error: null,
          loading: false,
          loaded: true,
          list: {
            ...state.list,
            [id]: {
              ...state.list[id],
              ...payload,
            },
          },
          items: state.list[id]
            ? state.items.map(item => item.id === id ? { ...item, ...payload } : item)
            : [payload, ...state.items],
        };
      });
    return this;
  }
  buildListDeleteAction(action: AsyncThunk<void, number, {}>): this {
    this.builder
      .addCase(action.pending, state => { state.loading = true; })
      .addCase(action.rejected, (state, action) => {
        state.error = action.error?.message || '';
        state.loaded = false;
        state.loading = false;
      })
      .addCase(action.fulfilled, (state, action) => {
        const id = action.meta.arg;

        if (!id) {
          return state;
        }
        delete state.list[id];
        state.error = null;
        state.loading = false;
        state.loaded = true;
        state.items = state.items.filter(item => item.id !== id);
      });
    return this;
  }
}

export function factorySetAction<T>(state: T & IAdditionalApiStatuses, action: PayloadAction<T>): typeof state {
  return {
    ...state,
    error: null,
    loading: false,
    loaded: true,
    ...action.payload,
  };
}

export function factoryInitState<T>(init: T): typeof init & IAdditionalApiStatuses {
  return {
    ...init,
    error: null,
    loading: true,
    loaded: false,
  };
}

export function factoryInitStateLoadingFalse<T>(init: T): typeof init & IAdditionalApiStatuses {
  return {
    ...init,
    error: null,
    loading: false,
    loaded: false,
  };
}

export function factoryInitListState<T>(): IInitListState<T> {
  return {
    items: [],
    list: {},
    count: 0,
    next: null,
    previous: null,
    error: null,
    loading: true,
    loaded: false,
    appending: false,
  };
}

export function jsonToFormData(
  { payload, formData = new FormData(), rootName = '', ignoreList }:
  {
    payload: Record<string, AnyTODO>;
    formData?: FormData;
    rootName?: string;
    ignoreList?: string | string[];
  },
): FormData {
  appendFormData(payload, rootName);
  return formData;

  function appendFormData(data: unknown, root = '') {
    if (ignore(root)) {
      return;
    } else if (Array.isArray(data)) {
      if (data.length) {
        if (['number', 'string'].includes(typeof data[0])) {
          data.forEach((item) => appendFormData(item, root));
        } else {
          data.forEach((item, index) => appendFormData(item, `${root}[${index}]`));
        }
      } else {
        // skip appendFormData('', root);
      }
    } else if (data && !(data instanceof File) && !(data instanceof Blob) && typeof data === 'object') {
      Object.entries(data).forEach(([key, value]) =>
        !ignore(key) && appendFormData(value, buildKey(root, key)),
      );
    } else {
      const value = data === false
        ? '0'
        : data === true
          ? '1'
          : data || '';
      formData.append(root, value as (string | Blob));
    }
  }

  function buildKey(root: string, key: string): string {
    if (!root) {
      return key;
    }
    const isRootArray = root.slice(-1) === ']';
    return [root, key].join(isRootArray ? '' : '.');
  }

  function ignore(root: string): boolean {
    return [ignoreList].flat().some(x => x === root);
  }
}



function buildList<T extends { id?: number }>(list: WritableDraft<Record<number, T>>, array: T[], append?: boolean): Record<number, Draft<T> | T> {
  const newList = array.reduce<Record<number, T>>((acc, t) => {
    if (t['id']) {
      acc[t.id] = t;
    }
    return acc;
  }, {});
  return append ? { ...list, ...newList } : newList;
}
