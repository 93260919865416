import React, { createContext, useContext, useLayoutEffect } from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { useLocalStorage } from './useLocalStorage';
import { DARK_MODE_SCOPE } from 'components/ProfileMenu/DarkModeToggle';
import { themeConfig } from 'modules/App/App.constants';


// export function useDarkMode(): [boolean, Dispatch<SetStateAction<boolean>>] {
//   const [darkMode, setDarkMode] = useLocalStorage(DARK_MODE_SCOPE, false, false);
//   useLayoutEffect(() => {
//     document.body.classList[isOn ? 'add' : 'remove']('DarkMode');
//   }, [isOn]);
//   return [isOn, setMode];
// }

interface ThemeContextProps {
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}
const ThemeContext = createContext<ThemeContextProps>({ darkMode: false, setDarkMode: () => {} });

export const ThemeProvider: React.FC = ({ children }) => {
  const [darkMode, setDarkMode] = useLocalStorage(DARK_MODE_SCOPE, false, false);

  useLayoutEffect(() => {
    document.body.classList[darkMode ? 'add' : 'remove']('DarkMode');
  }, [darkMode]);

  const theme = createTheme(themeConfig(darkMode));

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};


export const useDarkMode = () => {
  const context = useContext(ThemeContext);
  return context;
};
