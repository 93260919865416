import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgInfoIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="12" y1="8" x2="12" y2="13" stroke="currentColor" strokeWidth="2" />
        <line x1="12" y1="14" x2="12" y2="16" stroke="currentColor" strokeWidth="2" />
        <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" />
      </svg>
    </SvgIconContainer>
  );
};
