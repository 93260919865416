import { useState, useEffect, useRef, RefObject, useCallback } from 'react';

/**
 * Options for the useElementWidth hook.
 */
interface UseElementWidthOptions {
  /**
   * Callback function invoked when the width changes.
   * @param width The new width of the element.
   */
  onWidthChange?: (width: number) => void;
  /**
   * Determines which width measurement to use:
   * 'contentRect': The width of the content area (excludes padding/border).
   * 'borderBox': The width including padding and border (uses borderBoxSize).
   * Defaults to 'contentRect'.
   */
  box?: 'contentRect' | 'borderBox';
}

/**
 * A hook to track the width changes of a DOM element using ResizeObserver.
 *
 * @template T The type of the DOM element being observed (defaults to HTMLElement).
 * @param {UseElementWidthOptions | ((width: number) => void)} [optionsOrCallback] - Options or a callback function.
 * @returns {number} - The current width of the element.
 */
export function useElementWidth<T extends Element = HTMLElement>(
  optionsOrCallback?: UseElementWidthOptions | ((width: number) => void),
): { width: number, refElement: RefObject<T> } {
  const refElement = useRef<T>(null);
  const options = typeof optionsOrCallback === 'object' ? optionsOrCallback : undefined;
  const callback = typeof optionsOrCallback === 'function' ? optionsOrCallback : options?.onWidthChange;
  const box = options?.box ?? 'contentRect';

  const getWidth = useCallback((): number => {
    return refElement.current?.getBoundingClientRect?.()?.width || 0;
  }, [refElement]);

  const [width, setWidth] = useState<number>(getWidth);

  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const element = refElement.current;

    if (!element || typeof ResizeObserver === 'undefined') {
      const currentWidth = getWidth();
      if (currentWidth !== width) {
        setWidth(currentWidth);
      }
      return;
    }

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (!entries || entries.length === 0) {
        return;
      }
      const entry = entries[0];
      let newWidth = 0;

      if (box === 'borderBox' && entry.borderBoxSize) {
        const borderBoxEntry = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;
        newWidth = borderBoxEntry.inlineSize;
      } else {
        newWidth = entry.contentRect.width;
      }

      setWidth((prevWidth) => {
        if (newWidth !== prevWidth) {
          callbackRef.current?.(newWidth);
          return newWidth;
        }
        return prevWidth;
      });
    });

    observer.observe(element);

    return () => {
      if (element) {
        observer.unobserve(element);
      }
      observer.disconnect();
    };
  }, [refElement, getWidth, box, width]);

  return { width, refElement };
}
