import { AnyAction, createAsyncThunk, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Language } from 'i18n';
import { API, API_ROUTES } from 'modules/Api';
import { STATUS_CODES, UserStatusEnum } from 'constants/general';
import { infoPopupActions } from 'actions/infoPopupAction';
import Logger from 'modules/Logger';
import { RootState } from 'store/store.types';
import { TagManagerService } from 'services/dataLayer.service';
import { resetUserSettings, setUserSettings } from './settings.slice';
import { resetUserAvatar, setUserAvatar } from './avatar.slice';
import { IAdditionalApiStatuses } from '../slices/interfaces';
import { FactoryBuilder, factoryInitState } from '../slices/helpers';
import { resetSubscription, setSubscription } from './subscription.slice';
import { resetCompany } from 'actions/companyActions';
import { AnyTODO } from 'core/interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';


enum LocalStoreEnums {
  token = 'token',
  email = 'email',
}

interface ICompanySupportManager {
  avatar?: string;
  first_name: string,
  last_name: string,
  phone_number: string;
}

export interface IManagedCompany {
  id: number;
  country_id: number;
  name?: string;
  local_name?: string;
  is_verified: boolean;
  support_manager: ICompanySupportManager | null;
}

export interface IUser {
  token: string; // TODO: move
  id: number | null;
  company: string;
  company_id: number | null;
  contact_name: string;
  email: string;
  email_verified: boolean;
  is_company_admin: boolean;
  language: string;
  last_name: string;
  phone_number: string;
  is_phone_verified: boolean;
  is_company_verified?: boolean;
  managed_companies: IManagedCompany[];
  my_company?: IManagedCompany;
}


const initialData: IUser = {

  [LocalStoreEnums.token]: '',
  [LocalStoreEnums.email]: '',

  id: null,
  company: '',
  company_id: null,
  contact_name: '',
  // email: '', // from localStorage
  email_verified: false,
  is_company_admin: false,
  language: Language.default.key,
  last_name: '',
  phone_number: '',
  is_phone_verified: false,
  is_company_verified: false,
  managed_companies: [],
};
const initialState: IUser & IAdditionalApiStatuses = factoryInitState(initialData);
initialState[LocalStoreEnums.token] = getLocalStored(LocalStoreEnums.token);
initialState[LocalStoreEnums.email] = getLocalStored(LocalStoreEnums.email);


export const NAME = 'user';
const parent = 'profile';
const scope = `${parent}/${NAME}`;

export const getUser = createAsyncThunk<IUser, void>(
  `${scope}/get`,
  (_, { dispatch }) => {
    return API.get<IUser>(API_ROUTES.authMe)
      .then(updateProfile<IUser>(dispatch))
      .catch(error => {
        dispatch(setToken(null));
        throw error;
      });
  },
  { condition: isAuthCondition },
);

export const updateUser = createAsyncThunk(
  `${scope}/update`,
  async (
    payload: Partial<IUser>,
    { dispatch, getState, rejectWithValue },
  ): Promise<Partial<IUser>> => {
    const { [parent]: { [NAME]: { id } } } = getState() as RootState;
    if (!id) {
      return payload;
    }
    try {
      const result = await API.patch<IUser>(`/users/${id}/`, payload);
      return updateProfile(dispatch)(result);
    } catch (error) {
      Logger.debug(error);
      throw rejectWithValue(error);
    }
  },
);

export const emailVerification = createAsyncThunk<IUser, { activation_token: string }>(
  `${scope}/emailVerification`,
  (payload, { dispatch }) =>
    API.post<IUser>(API_ROUTES.activation.post, payload)
      .then(updateProfile<IUser>(dispatch))
      .catch(error => {
        Logger.debug(error);
        dispatch(infoPopupActions.apiErrors(error));
        throw error;
      })
  ,
);

export const login = createAsyncThunk<IUser, { username: string; password: string; invite_token?: string }> (
  `${scope}/login`,
  (payload, { dispatch }) =>
    API.post<IUser>('/auth/', payload)
      .then(updateProfile<IUser>(dispatch, () => { TagManagerService.events.login; }))
      .catch(error => {
        Logger.debug(error);
        dispatch(infoPopupActions.error('Неправильный логин или пароль'));
        throw error;
      })
  ,
);

export const loginGoogle = createAsyncThunk(
  `${scope}/loginGoogle`,
  (
    payload: { token?: string, invite_token?: string },
    { dispatch, getState },
  ): Promise<IUser> => {
    const referral_data = (getState() as RootState).systemSettings.refferalData;
    return API.post<IUser>('/users/google/', { ...payload, referral_data })
      .then(updateProfile<IUser>(dispatch, () => { TagManagerService.events.login; }))
      .catch(error => {
        Logger.debug(error);
        dispatch(infoPopupActions.error('infoPopup.errors.wrongGoogleToken'));
        throw error;
      });
  },
);

export const shortLinkVerification = createAsyncThunk<IUser, { otp_token: string }>(
  `${scope}/short-link-verification`,
  (payload, { dispatch } ): Promise<IUser> =>
    API.post<IUser>(API_ROUTES.activation.check, payload)
      .then(updateProfile<IUser>(dispatch, () => { TagManagerService.events.login; }))
      .catch(error => {
        Logger.debug(error);
        dispatch(infoPopupActions.error('infoPopup.errors.wrongToken'));
        throw error;
      })
  ,
);

export const loginByLink = createAsyncThunk(
  `${scope}/login-by-link`,
  (payload: { activation_token: string }, { dispatch } ): Promise<IUser> =>
    API.post<IUser>(API_ROUTES.activation.post, payload)
      .then(updateProfile<IUser>(dispatch, () => { TagManagerService.events.login; }))
      .catch(error => {
        Logger.debug(error);
        dispatch(infoPopupActions.error('infoPopup.errors.wrongToken'));
        throw error;
      })
  ,
);

export const logout = createAsyncThunk(
  `${scope}/logout`,
  async (_: void, { dispatch, getState }): Promise<void> => {
    if (isAuthCondition(undefined, { getState })) {
      await API.post('/logout/');
    }
    dispatch(setToken(null));
    dispatch(resetUser());
    dispatch(resetUserAvatar());
    dispatch(resetUserSettings());
    dispatch(resetSubscription());
    dispatch(resetCompany());
  },
);

export const registrationShort = createAsyncThunk(
  `${scope}/registrationShort`,
  (
    payload: { email: string, invite_token?: string },
    { dispatch, getState },
  ): Promise<IUser> => {
    const referral_data = (getState() as RootState).systemSettings.refferalData;
    return API.post<IUser>('/users/registration/short/', { ...payload, referral_data })
      .then(updateProfile<IUser>(dispatch, () => { TagManagerService.events.registration_fast; }))
      .catch(error => {
        if ((error?.response?.data?.email || '').includes('user with this email address already exists.')) {
          dispatch(infoPopupActions.error('Извините, этот адрес e-mail уже занят другим пользователем'));
        } else dispatch(infoPopupActions.apiErrors(error));
        throw error;
      });
  },
);

export const authPin = createAsyncThunk<IUser, { email: string; pin: string; invite_token?: string }> (
  `${scope}/authPin`, (payload, { dispatch }) =>
    API
      .post('/users/auth/pin/', payload)
      .then(updateProfile<IUser>(dispatch, () => { TagManagerService.events.login; }))
      .catch(error => {
        if (error?.response?.status === STATUS_CODES.badRequest) {
          dispatch(infoPopupActions.error('Проверьте введенные данные, неправильный email или пин'));
        } else if (error?.response?.status === STATUS_CODES.notFound) {
          dispatch(infoPopupActions.error('Сначала создайте учетную запись, для быстрой авторизации'));
        } else dispatch(infoPopupActions.apiErrors(error));
        throw error;
      })
  ,
);

export const registration = createAsyncThunk(
  `${scope}/registration`,
  (
    payload: { email: string, invite_token?: string },
    { dispatch, getState },
  ): Promise<IUser> => {
    const referral_data = (getState() as RootState).systemSettings.refferalData;
    return API
      .post<IUser>('/users/registration/', { ...payload, referral_data })
      .then(updateProfile<IUser>(dispatch, () => { TagManagerService.events.registration_regular; }))
      .catch(error => {
        if ((error?.response?.data?.email || '').includes('user with this email address already exists.')) {
          dispatch(infoPopupActions.error('Извините, этот адрес e-mail уже занят другим пользователем'));
        } else dispatch(infoPopupActions.apiErrors(error));
        throw error;
      });
  },
);

export interface IUserRegistration {
  uuid: string;
  email: string;
  contact_name: string;
  last_name: string;
  phone_number: string;
  company?: {
    name: string;
    local_name: string;
    address: string;
    country: number | null;
    site?: string;
    description?: string;
  };
}


export const newRegistration = createAsyncThunk(`${scope}/newRegistration`,
  (payload: IUserRegistration, { dispatch, getState, rejectWithValue }): Promise<IUser> => {
    const referral_data = (getState() as RootState).systemSettings.refferalData;
    const invite_token = (getState() as RootState).invite.company_invite_token;

    return API
      .post<IUser>(API_ROUTES.users.new_registration, { ...payload, referral_data, ...(invite_token && { invite_token }) })
      .then(updateProfile<IUser>(dispatch, () => { TagManagerService.events.registration_regular; }))
      .catch(error => { throw rejectWithValue(error); });
  },
);

export const isAuthSelector = (state: RootState): boolean => Boolean(state[parent][NAME].token);
export const tokenSelector = (state: RootState): string | null => state[parent][NAME].token;
export const companyIdSelector = (state: RootState): number | null => state[parent][NAME].company_id;
export const languageSelector = (state: RootState): string | null => state[parent][NAME].language;
export const userIdSelector = (state: RootState): number | null => state[parent][NAME].id;
export const profileUserSelector = (state: RootState): typeof initialState => state[parent][NAME];
export const canClone = (state: RootState): boolean => {
  const { email_verified, company_id, is_phone_verified } = state[parent][NAME];
  return Boolean(email_verified && company_id && is_phone_verified);
};
export const profileStatusSelector = ({ [parent]: { [NAME]: { is_company_admin, company_id, managed_companies } } }: RootState): UserStatusEnum => {
  if (!company_id) {
    return UserStatusEnum.UserWithoutCompany;
  }
  if (is_company_admin || managed_companies?.find(({ id }) => id === company_id)) {
    return UserStatusEnum.Admin;
  }
  return UserStatusEnum.User;
};

export const {
  reducer,
  actions: {
    setToken,
    setEmail,
    setUser,
    resetUser,
  },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setToken: setLocal(LocalStoreEnums.token),
    setEmail: setLocal(LocalStoreEnums.email),
    setUser(state, action: PayloadAction<Partial<IUser>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetUser: () => (factoryInitState(initialData)),
  },
  extraReducers: builder => new FactoryBuilder(builder)
    .buildAction(getUser)
    .buildAction(login)
    .buildAction(loginGoogle)
    .buildAction(shortLinkVerification)
    .buildAction(loginByLink)
    .buildAction(emailVerification)
    .buildAction(registrationShort)
    .buildAction(registration)
    .buildAction(newRegistration)
    .buildAction(updateUser)
    .buildAction(authPin)
    .buildAction(logout)
  ,
});

export default reducer;


function getLocalStored(key: string): string {
  return localStorage.getItem(key) || '';
}

function setLocal(key: LocalStoreEnums) {
  return (state: typeof initialState, action: PayloadAction<string | null>) => {
    state[key] = action.payload || '';
    if (!action.payload) localStorage.removeItem(key);
    else localStorage.setItem(key, action.payload);
  };
}

function isAuthCondition(_: void, { getState }: { getState: () => unknown }): boolean {
  const state: RootState = getState() as RootState;
  const isAuth: boolean = isAuthSelector(state);
  if (isAuth) return true; // TODO: TS issue
  return false;
}

function updateProfile<T extends Record<string, AnyTODO>>(dispatch: ThunkDispatch<unknown, unknown, AnyAction>, cb?: () => void): (data: { data: T }) => T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ({ data }) => {
    if ('settings' in data) {
      dispatch(setUserSettings(data.settings));
      delete data.settings;
    }
    if ('avatar' in data) {
      dispatch(setUserAvatar(data.avatar));
      delete data.avatar;
    }
    if ('subscription' in data) {
      dispatch(setSubscription(data.subscription));
      delete data.subscription;
    }
    cb?.();
    return data;
  };
}
