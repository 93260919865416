import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgNotificationsCloseIcon: React.FC<{ className?: string }> = ({ className }) => {

  return (
    <SvgIconContainer classes={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.95898 4L11.9186 11.9998L3.95898 19.9996" stroke="currentColor" strokeWidth="2" />
        <path d="M20.041 4.00049L12.0814 12.0003L20.041 20.0001" stroke="currentColor" strokeWidth="2" />
      </svg>
    </SvgIconContainer>
  );
};
