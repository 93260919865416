import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageHelper } from 'utils/LocalStorage.helper';

const VERSION = 1;
export const LOCALSTORAGE_KEY = 'settings';

export interface ISystemSettings {
  v: number;
  refferalData?: {
    referral_url: string;
    url: string;
    registered_at: string;
  },
  compactMode?: boolean;
}

type ActionKeyValue<T> = {
  [K in keyof T]: { key: K; value: T[K] };
}[keyof T];

export const NAME = 'systemSettins';
// const scope = `${NAME}`;
const defaultValue: ISystemSettings = {
  v: VERSION,
};

const initialState: ISystemSettings = new LocalStorageHelper<ISystemSettings>(LOCALSTORAGE_KEY).getValue(defaultValue);

export const {
  reducer,
  actions: {
    // setSystemSettingsState,
    setSystemSettingsByKey,
    setReferralData,
  },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
    // setSystemSettingsState: (_, action: PayloadAction<ISystemSettings>) => action.payload,
    setSystemSettingsByKey: (state, action: PayloadAction<ActionKeyValue<ISystemSettings>>) => {
      const key = action.payload?.key;
      const value = action.payload?.value;
      state[key] = value;
    },
    setReferralData: (state) => {
      if (state.refferalData) {
        return;
      }
      state.refferalData = {
        referral_url: window.document.referrer,
        url: window.location.href,
        registered_at: (new Date()).toJSON(),
      };
    },
  },
});

export default reducer;
