export const SCOPE_CARD_SECTION = 'CardSection';

export const uk = {
  left: 'Залишилось',
  closed: 'Закрита',
  by_lot: 'По Заявці',
  buttons: {
    similar: 'Подібні заявки',
    opposite: 'Зустрічні заявки',
  },
  ChatButton: {
    label: 'Надіслати повідомлення',
  },
};

export const en: typeof uk = {
  left: 'Left',
  closed: 'Closed',
  by_lot: 'By request',
  buttons: {
    similar: 'Similar requests',
    opposite: 'Opposit requests',
  },
  ChatButton: {
    label: 'Send message',
  },
};

export const ru: typeof uk = {
  left: 'Осталось',
  closed: 'Закрыта',
  by_lot: 'По Заявке',
  buttons: {
    similar: 'Подобные заявки',
    opposite: 'Встречные заявки',
  },
  ChatButton: {
    label: 'Отправить сообщение',
  },
};

export const bg: typeof uk = {
  left: 'Остават',
  closed: 'Закрыта',
  by_lot: 'По Заявка',
  buttons: {
    similar: 'Подобни заявки',
    opposite: 'Контрарни заявки',
  },
  ChatButton: {
    label: 'Изпратете съобщение',
  },
};
