import React from 'react';
import { TextField, Switch, Button, Tooltip, createStyles, Theme, withStyles, WithStyles, SwitchProps } from '@material-ui/core';

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '15px',
    lineHeight: '22px',
    minWidth: '168px',
    textAlign: 'center',
    padding: '10px 8px 8px 8px',
    color: 'var(--white)',
    background: 'var(--black)',
    borderRadius: '10px',
  },
  arrow: {
    color: 'var(--black)',
    fontSize: '12px',
  },
})(Tooltip);

export const CustomTextAreaField = withStyles({
  root: {
    marginBottom: 20,
    '& label': {
      color: 'var(--gray800)',
      opacity: 0.42,
    },
    '& label.Mui-focused': {
      color: 'var(--gray800)',
    },
    '& .MuiInputBase-root': {
      border: 'none',
      '& .MuiInputBase-input': {
        color: 'var(--black)',
        background: 'var(--gray400)',
        borderRadius: 10,
        paddingRight: 40,
      },
      '& .MuiOutlinedInput-inputMultiline': {
        padding: '18.5px 14px',
      },
      '& textarea.MuiInputBase-input.MuiOutlinedInput-inputMultiline': {
        lineHeight: 1.5,
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.MuiOutlinedInput-multiline': {
        padding: 0,
      },
      '& fieldset': {
        // border: 'none',
      },
    },
  },
})(TextField);

const textFieldStyle = {
  root: {
    marginBottom: 20,
    '& label': {
      color: 'var(--gray800)',
      opacity: 0.42,
    },
    '& label.Mui-focused': {
      color: 'var(--black)',
    },
    '& .MuiInputBase-root': {
      border: 'none',
      '& .MuiInputBase-input': {
        color: 'var(--black)',
        background: 'var(--gray400)',
        borderRadius: 10,
        paddingRight: 40,
      },
      '& .MuiOutlinedInput-inputMultiline': {
        padding: '18.5px 14px',
      },
      '& textarea.MuiInputBase-input.MuiOutlinedInput-inputMultiline': {
        lineHeight: 1.5,
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.MuiOutlinedInput-multiline': {
        padding: 0,
      },
      '& fieldset': {
        border: 'none',
      },
    },
  },
};
export const CustomTextField = withStyles(textFieldStyle)(TextField);

export const CTAButton = withStyles({
  root: {
    height: 56,
    backgroundColor: 'var(--CTA)',
    color: 'var(--white)',
    fontSize: 15,
    lineHeight: 'unset',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--CTAHover)',
    },
  },
})(Button);

export const AddButton = withStyles({
  root: {
    height: 56,
    fontWeight: 700,
    padding: '6px 20px',
    backgroundColor: 'var(--gray400)',
    color: 'var(--primary)',
    fontSize: 15,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--gray500)',
    },
  },
})(Button);

export const SaveButton = withStyles({
  root: {
    height: 56,
    fontWeight: 700,
    padding: '6px 20px',
    backgroundColor: 'var(--primary)',
    color: 'var(--white)',
    fontSize: 15,
    maxWidth: 'max-content',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--primaryDarken)',
    },
    '&:disabled': {
      backgroundColor: 'var(--gray500)',
    },
  },
})(Button);

export const SaveButtonNotStrict = withStyles({
  root: {
    height: 56,
    fontWeight: 700,
    padding: '6px 20px',
    backgroundColor: 'var(--primary)',
    color: 'var(--white)',
    fontSize: 15,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--primaryDarken)',
    },
    '&:disabled': {
      backgroundColor: 'var(--gray500)',
    },
  },
})(Button);

export const GreenButton = withStyles({
  root: {
    fontWeight: 700,
    padding: '11px 20px',
    backgroundColor: 'var(--primary)',
    color: 'var(--white)',
    fontSize: 15,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--primaryDarken)',
    },
  },
})(Button);



const SwitchStyle = (theme: Theme) => createStyles({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    backgroundColor: 'var(--gray500)',
    border: 'none',
    '&$checked': {
      transform: 'translateX(22px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'var(--primary)',
        // opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: 'var(--primary)',
      border: '6px solid var(--white)',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: '1px solid var(--gray600)',
    backgroundColor: 'var(--gray600)',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
});

const SwitchEx: React.FC<SwitchProps & WithStyles<typeof SwitchStyle>> = ({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export const Toggle = withStyles(SwitchStyle)(SwitchEx);
