import store from 'store/store';
import Nav, { ROUTES } from 'nav';
import { ICategory } from 'reducers/allReducer';
import { generatePath } from 'react-router';

export type { ICategory };
export { Nav };

export class Category {
  protected _store!: ReturnType<typeof store['getState']>;

  static find(id: number): Category {
    return new this(this.store.all.categories.list[id]);
  }
  static build(item?: Partial<ICategory>): Category | undefined {
    return item
      ? new this(item)
      : undefined;
  }

  private static get store() {
    return store.getState();
  }

  constructor (
    public $: Partial<ICategory>,
  ) {}

  get id() { return this.$.id; }
  get name() { return this.$.name; }
  get seoTitle() { return this.$.seo_title; }
  get seoDescription() { return this.$.seo_description; }
  get seoKeywords() { return this.$.seo_keywords; }

  get path(): string {
    const categoryChurl = this.$.churl;
    if (categoryChurl) {
      return generatePath(ROUTES.categories, { categoryChurl });
    }
    const id = this.$.id;
    if (id) {
      return `${ROUTES.proposals}?${new URLSearchParams({ 'categories[]': `${id}` })}`;
    }
    return ROUTES.market;
  }
  get marketPath(): string {
    const categoryChurl = this.$.churl;
    if (categoryChurl) {
      return generatePath(ROUTES.marketCategory, { categoryChurl });
    }
    return ROUTES.market;
  }
  go(toOptions?: { search?: string }): void {
    const to: Parameters<typeof Nav.go>[0] = { pathname: this.path };
    if (toOptions?.search) {
      to.search = toOptions?.search;
    }
    Nav.go(to, { vibrate: 'standard' });
  }
  goMarket(toOptions: { search?: string, market?: boolean } = {}): void {
    const to: Parameters<typeof Nav.go>[0] = {
      pathname: this.$?.churl && !toOptions.market
        ? generatePath(ROUTES.marketCategory, { categoryChurl: this.$.churl })
        : ROUTES.market,
      search: toOptions.search,
    };
    Nav.go(to, { vibrate: 'standard' });
  }

  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}
