import React, { Suspense } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { isAuthSelector, useTsSelector } from 'reducers/profile/user.slice';
import Preloader from 'components/_router/Preloader';
import { ROUTES } from 'constants/routes';
import { AnyTODO } from 'core/interfaces';

interface Props extends Omit<RouteProps, 'component' | 'render' | 'children'> {
  component: React.ComponentType<AnyTODO>;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const isAuth = useTsSelector(isAuthSelector);

  const needRedirect = !isAuth;

  if (needRedirect) {
    return <Redirect to={ROUTES.market} />;
  }

  return (
    <Route {...rest}
      render={(props: RouteComponentProps<AnyTODO>) =>
        <Suspense fallback={<Preloader />}>
          <Component {...props} />
        </Suspense>
      }
    />
  );
};


export default PrivateRoute;
