import React, { Suspense, lazy } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { Language } from 'i18n';
import { Helmet } from 'react-helmet';
import { NotFoundLayout } from 'layouts/NotFoundLayout';
import { GROUP_ROUTES, ROUTES } from 'constants/routes';
import { NotAuthRoute } from 'components/_router/NotAuthRoute';
import PrivateRoute from 'components/_router/PrivatRoute';
import { useTsSelector } from 'store/store.types';
import Preloader from '../../components/_router/Preloader';
import Invite from './Invite';
import QuickAuth from './QuickAuth';
import InfoPopup from './InfoPopup';
import Activation from './Activation';
import { LogoutPage } from './LogoutPage';
import GlobalAlert from './GlobalAlert';
import { HOTKEYS, useDarkMode, useHotkeys } from 'hooks';

const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const MainLayout = lazy(() => import('layouts/MainLayout'));
const LandingLayout = lazy(() => import('layouts/LandingLayout'));
// const LandingPagesLayout = lazy(() => import('layouts/LandingPagesLayout'));
const RegistrationLayout = lazy(() => import('layouts/RegistrationLayout'));

export const Root: React.FC = () => {
  // const params = useParams<{ language?: string }>();
  const location = useLocation();
  const user = useTsSelector(({ profile }) => profile.user);

  const { setDarkMode } = useDarkMode();
  useHotkeys([
    { call: () => setDarkMode(prev => !prev), keyCode: HOTKEYS.D, ctrlKey: true, altKey: true },
  ]);


  return <>
    <Helmet>
      <html lang={Language.current.key} />
      <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}/og/logo_v3.png`} />
      <meta property="og:url" content={Language.current.fullPath(`${location.pathname}${location.search}`)} />
      <meta property="og:locale" content={Language.current.key} />
      <meta property="og:type" content="website" />
      <link rel="canonical" href={Language.current.fullPath(location.pathname)} />
      {Language.items.map((language) =>
        <link key={language.key} href={language.fullPath(location.pathname)} rel="alternate" hrefLang={language.key} />,
      )}
    </Helmet>
    <InfoPopup />
    <GlobalAlert />
    <Suspense fallback={<Preloader />}>

      <Switch>
        <Route path={ROUTES.notFound} component={NotFoundLayout} />
        {/* <Route path={ROUTES.landingPages} component={LandingPagesLayout} /> */}
        <Route exact path={ROUTES.logout} component={LogoutPage} />
        <Route exact path={Object.values(ROUTES.landingPageLayout)} component={LandingLayout} />
        {/* <Route exact path={ROUTES.landingPageLayout.root} component={LandingLayout} /> */}
        <NotAuthRoute exact path={GROUP_ROUTES.authLayout} component={AuthLayout} />
        <PrivateRoute exact path={GROUP_ROUTES.registrationLayout} component={RegistrationLayout} />
        <Route path={ROUTES.activation} component={Activation} />
        <Route path={ROUTES.quickAuth} component={QuickAuth} />
        <Route path={ROUTES.invite} component={Invite} />
        <Route key={user.company_id} path={ROUTES.home} component={MainLayout} />
        <Redirect path="*" to={ROUTES.notFound} />
      </Switch>
    </Suspense>
  </>;
};
