import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgArrowBackIcon: React.FC<{ className?: string; mirrow?: boolean }> = ({ className , mirrow }) => {
  return (
    <SvgIconContainer className={className} mirrow={mirrow}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5L5.00001 12L12 19" stroke="currentColor" strokeWidth="2" />
        <line x1="6" y1="12" x2="21" y2="12" stroke="currentColor" strokeWidth="2" />
      </svg>
    </SvgIconContainer>
  );
};
