import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { infoPopupActions } from 'actions/infoPopupAction';
import { IBusinessCard } from 'api/businessCards';
import { toApiQuery } from 'api/utils';
import { AnyTODO } from 'core/interfaces';
import { API, API_ROUTES } from 'modules/Api';
import { IAudioField } from 'components/_forms/Fields/VoiceRecorderField';
import { factoryInitListState, FactoryListBuilder, jsonToFormData } from './helpers';
import { IApiListResponse, IInitListState } from './interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';

export interface IInvitedCompany {
  id: number;
  // image_data?: Partial<IBusinessCard>;
  name: string;
  email: string;
  status: 'init' | 'sent' | 'registered';
  owner: string;
  is_my: boolean;
  image?: string;
  user: string;
  user_company?: string;
  language: string | null;
  created_at: string;
  sent_at?: string;
  data?: IBusinessCard;
  summary_text?: string;
}

const initialState = factoryInitListState<IInvitedCompany>();

export const NAME = 'invited_companies';
const parent = 'global';
const scope = `${parent}/${NAME}`;
const apiUrlBase: string = API_ROUTES.invited_companies.root;

export const getInvitedCompanies = createAsyncThunk<IApiListResponse<IInvitedCompany> | null, { params: Record<string, AnyTODO>, append?: boolean }>(
  `${scope}/getList`,
  (payload) => API.get<IApiListResponse<IInvitedCompany>>(`${apiUrlBase}?${toApiQuery(payload.params)}`).then(res => res.data),
);

type CreateInvitedCompanyParams = Pick<Partial<IInvitedCompany>, 'id' | 'name' | 'email' | 'language'> & { image_data?: { image: string; audio: IAudioField[] }}

export const createInvitedCompany = createAsyncThunk<IInvitedCompany, CreateInvitedCompanyParams>(
  `${scope}/create`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await API.post<IInvitedCompany>(apiUrlBase, jsonToFormData({ payload }));
      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const updateInvitedCompany = createAsyncThunk<IInvitedCompany, Partial<IInvitedCompany>>(
  `${scope}/update`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await API.patch<IInvitedCompany>(`${apiUrlBase}${payload.id}/`, payload);
      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const sendInvitedCompany = createAsyncThunk<IInvitedCompany, { id: number }>(
  `${scope}/sendMail`,
  async (payload, { dispatch }) => {
    const apiUrl = API_ROUTES.invited_companies.sendEmail.replace(':id', `${payload.id}`);
    try {
      const { data } = await API.post<IInvitedCompany>(apiUrl);
      dispatch(infoPopupActions.success('sent'));
      return data;
    } catch (error) {
      dispatch(infoPopupActions.apiErrors(error as AnyTODO));
      throw error;
    }
  },
);


export const {
  reducer,
  // actions: {
  // },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
  },
  extraReducers: builder => new FactoryListBuilder<IInvitedCompany, IInitListState<IInvitedCompany>>(builder)
    .buildListAction(getInvitedCompanies)
    .buildListSetAction(createInvitedCompany)
    .buildListSetAction(sendInvitedCompany)
    .buildListSetAction(updateInvitedCompany)
  ,
});

export default reducer;
