import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgEditIcon: React.FC<{ className?: string; color?: string }> = ({ className, color = 'var(--gray800)' }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.66665 17H1.70841C1.52053 17 1.34034 16.9254 1.20749 16.7925C1.07464 16.6597 1 16.4795 1 16.2916V12.3334C0.999682 12.2414 1.0175 12.1502 1.05245 12.0651C1.0874 11.98 1.13879 11.9027 1.20367 11.8375L11.8298 1.21134C11.8957 1.1444 11.9743 1.09125 12.0609 1.05497C12.1476 1.01868 12.2406 1 12.3345 1C12.4285 1 12.5215 1.01868 12.6081 1.05497C12.6948 1.09125 12.7734 1.1444 12.8393 1.21134L16.7887 5.16072C16.8556 5.22663 16.9088 5.3052 16.945 5.39186C16.9813 5.47851 17 5.57152 17 5.66546C17 5.7594 16.9813 5.8524 16.945 5.93906C16.9088 6.02571 16.8556 6.10428 16.7887 6.1702L6.16253 16.7963C6.09733 16.8612 6.01996 16.9126 5.93487 16.9475C5.84978 16.9825 5.75863 17.0003 5.66665 17V17Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.5 3.54022L14.4589 8.49908" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </SvgIconContainer>
  );
};
