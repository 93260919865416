import { LotType } from 'constants/general';

interface AbstractT {
  id: number;
  created_at: string;
}
export interface Overall {
  [LotType.offer]?: {
    count: number;
    volume: number;
  };
  [LotType.request]?: {
    count: number;
    volume: number;
  };
}

export interface IListState<T extends AbstractT> {
  loading: boolean;
  appending: boolean;
  append: boolean;
  page: number;
  list: T[];
  nextRequests?: null | string;
  nextOffers?: null | string;
  next?: null | string;
  error: null | string;
  overalls: Overall;
  count: number;
}

function initialState<T extends AbstractT>(): IListState<T> {
  return {
    loading: false,
    appending: false,
    append: false,
    page: 0,
    list: [],
    nextRequests: null,
    nextOffers: null,
    error: null,
    overalls: {
      [LotType.offer]: {
        count: 0,
        volume: 0,
      },
      [LotType.request]: {
        count: 0,
        volume: 0,
      },
    },
    count: 0,
  };
}


interface Payload<T extends AbstractT> {
  payload: {
    append?: boolean;
    list: T[];
    count: number;
    nextRequests?: string | null;
    nextOffers?: string | null;
    overalls: Overall;
  };
}

function getSuccess<T extends AbstractT>(
  state: IListState<T>,
  { payload: { append, list, count, nextRequests, nextOffers, overalls } }: Payload<T>,
) {
  return {
    ...state,
    loading: false,
    appending: false,
    page: append ? state.page + 1 : 0,
    error: null,
    nextRequests,
    nextOffers,
    ...({ list: (append ? [...state.list, ...list] : list).sort((a, b) => b.created_at.localeCompare(a.created_at)) }),
    ...(!append && { count, overalls }),
  };
}

function setLoading<T extends AbstractT>(state: IListState<T>, { loading }: { loading: boolean }): IListState<T> {
  return { ...state, loading };
}
function setAppending<T extends AbstractT>(state: IListState<T>, { appending }: { appending: boolean }): IListState<T> {
  return { ...state, appending };
}

export default {
  initialState,
  getSuccess,
  setLoading,
  setAppending,
};
