import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgSearchIconBig: React.FC<{ className?: string }> = ({ className }) => {

  return (
    <SvgIconContainer className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="7" stroke="currentColor" strokeWidth="2" />
        <line x1="16.7071" y1="17.2929" x2="20.2426" y2="20.8284" stroke="currentColor" strokeWidth="2" />
      </svg>
    </SvgIconContainer>
  );
};
