import React from 'react';
import moment, { Moment } from 'moment';
import { FormatEnum } from 'constants/general';
import { classes } from 'utils';
import { formateDate, nextCurrentDateFormat, useTsDispatch, useTsSelector } from 'reducers/slices/settings.slice';
import './style.sass';

export const DateElement: React.FC<{ datetime?: string | Date | Moment; className?: string; }> = ({ datetime, className }) => {
  const formatedDate = useTsSelector(formateDate(datetime || ''));
  const dispatch = useTsDispatch();

  if (!datetime) {
    return null;
  }


  const title = moment(datetime).format(FormatEnum.full) + '\n' + moment(datetime).fromNow();
  const nextFormat: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(nextCurrentDateFormat());
  };

  return (
    <div
      title={title}
      className={classes('DateElement', className)}
      onClick={nextFormat}
    >
      {formatedDate}
    </div>
  );
};
