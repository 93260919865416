import { toUrlQuery } from 'api/utils';
import { LotType } from 'constants/general';
import { BuilderLinkTo, ROUTES } from 'constants/routes';
import { createBrowserHistory } from 'history';
import { Language } from 'i18n';
import Logger from 'modules/Logger';
import { generatePath } from 'react-router';
import Notificate, { NotificateType } from 'services/notificate.service';

export let history: ReturnType<typeof createBrowserHistory>;
export const initHistory = (language: Language) => {
  history = history
    ? history
    : createBrowserHistory({ basename: language.path });
  return history;
};
export { ROUTES } from 'constants/routes';

export interface NavOptions {
  state?: unknown;
  replace?: boolean;
  vibrate?: NotificateType;
  query?: Record<string, string | number>;
  generate?: Record<string, string | number>;
}

let _proxyOptions: NavOptions = {};

export default class Nav {
  private static readonly proxy = buildProxy();
  private static readonly pathProxy = buildPathProxy();

  static go(
    to: Parameters<typeof history.replace>[0], options: NavOptions = {},
  ): void {
    const trTo = this.transformTo(to, options);
    options.replace ? history.replace(trTo, options.state) : history.push(trTo, options.state);
    if (options.vibrate) {
      Notificate.run(options.vibrate);
    }
  }
  static transformTo(to: Parameters<typeof history.replace>[0], options: NavOptions): Parameters<typeof history.replace>[0] {
    const toG = options.generate && typeof to == 'string' ? generatePath(to, options.generate): to;
    return options.query ? `${toG}${String(toG).includes('?') ? '&' : '?'}${toUrlQuery(options.query)}` : toG;
  }
  static replace(location: Parameters<typeof history.replace>[0]): void {
    history.replace(location);
  }
  static changeLanguage(language?: Language) {
    if (!language) {
      return;
    }
    const breadsOfPath = window.location.pathname.split('/');
    const search = window.location.search;
    const currentLanguagePath = breadsOfPath[1];
    if (language.path === currentLanguagePath) {
      return;
    }
    // i18n.changeLanguage(language.key);
    if (Language.findByPath(currentLanguagePath)) {
      breadsOfPath[1] = language.path;
      window.history.pushState(null, 'hi', `/${breadsOfPath.slice(1, 999).join('/')}${search}`);
      window.location.reload();
    } else {
      window.history.replaceState(null, 'hi2', `/${language.path}${window.location.pathname}${search}`);
      window.location.reload();
    }
    return this;
  }
  static routes(options: NavOptions = {}): Readonly<typeof ROUTES> {
    _proxyOptions = options;
    return this.proxy;
  }
  static pathes(options: NavOptions = {}): Readonly<typeof ROUTES> {
    _proxyOptions = options;
    return this.pathProxy;
  }
  static search(params: Parameters<typeof toUrlQuery>[0], { replace = true }: { replace?: boolean } = {}): void {
    const search = toUrlQuery(params);
    replace ? history.replace({ search }) : history.push({ search });
  }
  static lot({ type, id }: { type: LotType, id: number }): void {
    const to: string = generatePath(ROUTES.lot, { type, id });
    this.go(to);
  }
  static goToTrade(instance?: { id?: number }, { replace = false }: { replace?: boolean } = {}): void {
    const to: string = BuilderLinkTo.trade(instance);
    if (to) this.go(to, { replace });
  }
  static createLot({ type, replace }: { type: LotType, replace?: boolean } = { type: LotType.offer }): void {
    const to: string = generatePath(ROUTES.createLot, { type });
    this[replace ? 'replace' : 'go'](to);
  }
  static editLot({ type, id }: { type: LotType, id: number }): void {
    const to: string = generatePath(ROUTES.editLot, { type, id });
    this.go(to);
  }
  // static tradeLot({ type, id }: { type: LotType, id: number }): void {
  //   const to: string = generatePath(ROUTES.tradeLot, { type, id });
  //   this.go(to);
  // }
  static cloneLot({ type, id }: { type: LotType, id: number }): void {
    const to: string = generatePath(ROUTES.cloneLot, { type, id });
    this.go(to);
  }
  static editAddress({ id }: { id?: number }, options?: NavOptions): void {
    if (!id) {
      Logger.debug('editAddress', 'wrongId');
      return;
    }
    const to: string = generatePath(ROUTES.settings.addresses.edit, { id });
    this.go(to, options);
  }
  static createAddress(options?: NavOptions): void {
    const to: string = generatePath(ROUTES.settings.addresses.create);
    this.go(to, options);
  }
  static showAddress({ id }: { id?: number }, options?: NavOptions): void {
    if (!id) {
      Logger.debug('showAddress', 'wrongId');
      return;
    }
    const to: string = generatePath(ROUTES.settings.addresses.show, { id });
    this.go(to, options);
  }
}

interface IRoutes {
  [key: string]: string | IRoutes;
}

function buildProxy(): Readonly<typeof ROUTES> {
  const handler = {
    get(target: IRoutes, p: string): IRoutes | void {
      if (p in target) {
        const value = target[p];
        if (typeof value === 'object') {
          return new Proxy(value, handler);
        }
        Nav.go(value, _proxyOptions);
      }
    },
  };

  return new Proxy(ROUTES, handler) as Readonly<typeof ROUTES>;
}

function buildPathProxy(): Readonly<typeof ROUTES> {
  const handler = {
    get(target: IRoutes, p: string): IRoutes | Parameters<typeof history.replace>[0] {
      if (p in target) {
        const value = target[p];
        if (typeof value === 'object') {
          return new Proxy(value, handler);
        }
        return Nav.transformTo(value, _proxyOptions);
      }
      return p;
    },
  };

  return new Proxy(ROUTES, handler) as Readonly<typeof ROUTES>;
}
