import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgTickIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <SvgIconContainer classes={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5 13L9.5 19L20.5 8" stroke="currentColor" strokeWidth="2" />
      </svg>
    </SvgIconContainer>
  );
};

export default SvgTickIcon;
