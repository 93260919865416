import { CompanyHandshakeSubStatus } from 'models/CompanyHandshake';
import { NOTIFICATION_TRADE_TYPES, NOTIFICATION_TYPES } from 'modules/Notifications/NotificationsItem';

export const SCOPE_NOTIFICATIONS = 'Notifications';

export const uk = {
  [NOTIFICATION_TYPES.UserRegistrationFlow]: {
    title: 'Реєстрація по інвайту',
    description: 'Новий користувач {{contact_name}}',
    description_with_company: 'Новий користувач {{contact_name}}, компанія "{{company_name}}"',
  },
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Відміна рукостискання',
      [CompanyHandshakeSubStatus.my]: 'Запит на рукостискання',
      [CompanyHandshakeSubStatus.full]: 'Підтвердження рукостискання',
    },
    description: 'від {{company}}',
  },
  [NOTIFICATION_TYPES.trade]: {
    titles: {
      request: {
        [NOTIFICATION_TRADE_TYPES.soft_bid]: 'Ціна закупівлі',
        [NOTIFICATION_TRADE_TYPES.hard_bid]: 'Тверда пропозиція закупівлі',
        [NOTIFICATION_TRADE_TYPES.message]: 'Отримано повідомлення',
        [NOTIFICATION_TRADE_TYPES.confirmed]: 'Підтвердження покупки',
        [NOTIFICATION_TRADE_TYPES.refused]: 'Відмова від покупки',
        [NOTIFICATION_TRADE_TYPES.changed]: 'Дані покупки були змінені',
        [NOTIFICATION_TRADE_TYPES.expired]: 'Термін дії продажу минув',
      },
      offer: {
        [NOTIFICATION_TRADE_TYPES.soft_bid]: 'Ціна продажу',
        [NOTIFICATION_TRADE_TYPES.hard_bid]: 'Тверда пропозиція продажу',
        [NOTIFICATION_TRADE_TYPES.message]: 'Отримано пропозицію',
        [NOTIFICATION_TRADE_TYPES.confirmed]: 'Підтвердження продажу',
        [NOTIFICATION_TRADE_TYPES.refused]: 'Відмова від продажу',
        [NOTIFICATION_TRADE_TYPES.changed]: 'Дані продажу були змінені',
        [NOTIFICATION_TRADE_TYPES.expired]: 'Термін дії продажу минув',
      },
    },
    description: 'від {{company}}. {{culture}}. Лот №{{lotId}}',
  },
};

export const en: typeof uk = {
  [NOTIFICATION_TYPES.UserRegistrationFlow]: {
    title: 'Registration by invitation',
    description: 'New user {{contact_name}}',
    description_with_company: 'New user {{contact_name}}, company "{{company_name}}"',
  },
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Revoking Handshake',
      [CompanyHandshakeSubStatus.my]: 'Requesting Handshake',
      [CompanyHandshakeSubStatus.full]: 'Confirming Handshake',
    },
    description: 'from {{company}}',
  },
  [NOTIFICATION_TYPES.trade]: {
    titles: {
      request: {
        [NOTIFICATION_TRADE_TYPES.soft_bid]: 'Buyer\'s price',
        [NOTIFICATION_TRADE_TYPES.hard_bid]: 'Buyer\'s bid',
        [NOTIFICATION_TRADE_TYPES.message]: 'Request has received',
        [NOTIFICATION_TRADE_TYPES.confirmed]: 'Purchase confirmation',
        [NOTIFICATION_TRADE_TYPES.refused]: 'Refused purchase',
        [NOTIFICATION_TRADE_TYPES.changed]: 'Request has been changed',
        [NOTIFICATION_TRADE_TYPES.expired]: 'Offer has expired',
      },
      offer: {
        [NOTIFICATION_TRADE_TYPES.soft_bid]: 'Seller\'s price',
        [NOTIFICATION_TRADE_TYPES.hard_bid]: 'Seller\'s offer',
        [NOTIFICATION_TRADE_TYPES.message]: 'Offer has received',
        [NOTIFICATION_TRADE_TYPES.confirmed]: 'Sale confirmation',
        [NOTIFICATION_TRADE_TYPES.refused]: 'Refused sale',
        [NOTIFICATION_TRADE_TYPES.changed]: 'Request has been changed',
        [NOTIFICATION_TRADE_TYPES.expired]: 'Offer has expired',
      },
    },
    description: 'from {{company}}. {{culture}}. Lot #{{lotId}}',
  },
};

export const ru: typeof uk = {
  [NOTIFICATION_TYPES.UserRegistrationFlow]: {
    title: 'Регистрация по инвайту',
    description: 'Новый пользователь {{contact_name}}',
    description_with_company: 'Новый пользователь {{contact_name}}, компания "{{company_name}}"',
  },
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Отмена рукопожатия',
      [CompanyHandshakeSubStatus.my]: 'Запрос на рукопожатие',
      [CompanyHandshakeSubStatus.full]: 'Подтверждение рукопожатия',
    },
    description: 'от {{company}}',
  },
  [NOTIFICATION_TYPES.trade]: {
    titles: {
      request: {
        [NOTIFICATION_TRADE_TYPES.soft_bid]: 'Цена закупки',
        [NOTIFICATION_TRADE_TYPES.hard_bid]: 'Твёрдое предложение закупки',
        [NOTIFICATION_TRADE_TYPES.message]: 'Получен запрос',
        [NOTIFICATION_TRADE_TYPES.confirmed]: 'Подтверждение покупки',
        [NOTIFICATION_TRADE_TYPES.refused]: 'Отказ покупки',
        [NOTIFICATION_TRADE_TYPES.changed]: 'Данные покупки были изменены',
        [NOTIFICATION_TRADE_TYPES.expired]: 'Cрок действия продажи истек',
      },
      offer: {
        [NOTIFICATION_TRADE_TYPES.soft_bid]: 'Цена продажи',
        [NOTIFICATION_TRADE_TYPES.hard_bid]: 'Твёрдое предложение продажи',
        [NOTIFICATION_TRADE_TYPES.message]: 'Полученo предложение',
        [NOTIFICATION_TRADE_TYPES.confirmed]: 'Подтверждение продажи',
        [NOTIFICATION_TRADE_TYPES.refused]: 'Отказ от продажи',
        [NOTIFICATION_TRADE_TYPES.changed]: 'Данные продажи были изменены',
        [NOTIFICATION_TRADE_TYPES.expired]: 'Cрок действия продажи истек',
      },
    },
    description: 'от {{company}}. {{culture}}. Лот №{{lotId}}',
  },
};

export const bg: typeof uk = {
  [NOTIFICATION_TYPES.UserRegistrationFlow]: {
    title: 'Регистрация с покана',
    description: 'Нов потребител {{contact_name}}',
    description_with_company: 'Нов потребител {{contact_name}}, компания "{{company_name}}"',
  },
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Извикване на ръкостискане',
      [CompanyHandshakeSubStatus.my]: 'Искане за ръкостискане',
      [CompanyHandshakeSubStatus.full]: 'Потвърждение на ръкостискане',
    },
    description: 'от {{company}}',
  },
  [NOTIFICATION_TYPES.trade]: {
    titles: {
      request: {
        [NOTIFICATION_TRADE_TYPES.soft_bid]: 'Покупна цена',
        [NOTIFICATION_TRADE_TYPES.hard_bid]: 'Твърда оферта за покупка',
        [NOTIFICATION_TRADE_TYPES.message]: 'Заявката е получена',
        [NOTIFICATION_TRADE_TYPES.confirmed]: 'Потвърждение на покупката',
        [NOTIFICATION_TRADE_TYPES.refused]: 'Отказана покупка',
        [NOTIFICATION_TRADE_TYPES.changed]: 'Данните покупки бяха изменени',
        [NOTIFICATION_TRADE_TYPES.expired]: 'Продажбата е изтекла',
      },
      offer: {
        [NOTIFICATION_TRADE_TYPES.soft_bid]: 'Продажна цена',
        [NOTIFICATION_TRADE_TYPES.hard_bid]: 'Твърда оферта за продажба',
        [NOTIFICATION_TRADE_TYPES.message]: 'Офертата е получена',
        [NOTIFICATION_TRADE_TYPES.confirmed]: 'Потвърждение на продажба',
        [NOTIFICATION_TRADE_TYPES.refused]: 'Отказана продажба',
        [NOTIFICATION_TRADE_TYPES.changed]: 'Данните продажбите бяха изменени',
        [NOTIFICATION_TRADE_TYPES.expired]: 'Продажбата е изтекла',
      },
    },
    description: 'от {{company}}. {{culture}}. Лот №{{lotId}}',
  },
};
