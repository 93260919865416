import React from 'react';
import Brightness4 from '@material-ui/icons/Brightness4';
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useDarkMode } from 'hooks';
import './style.sass';

export const DARK_MODE_SCOPE = 'DarkModeToggle';

export const DarkModeToggle: React.FC = () => {
  const { darkMode, setDarkMode } = useDarkMode();


  return (
    <ToggleButtonGroup exclusive
      className="DarkModeToggle"
      size="small"
      value={darkMode}
      onChange={(_ev, value) => { setDarkMode(value); }}
      aria-label="text alignment"
    >
      <ToggleButton value={false} aria-label="left aligned">
        <WbSunnyRoundedIcon />
      </ToggleButton>
      <ToggleButton value={true} aria-label="right aligned">
        <Brightness4 />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
