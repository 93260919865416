import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgMobileMenuIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="3" y1="5" x2="21" y2="5" stroke="currentColor" strokeWidth="2"/>
        <line x1="3" y1="12" x2="21" y2="12" stroke="currentColor" strokeWidth="2"/>
        <line x1="3" y1="19" x2="21" y2="19" stroke="currentColor" strokeWidth="2"/>
      </svg>
    </SvgIconContainer>
  );
};
