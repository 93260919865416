import React from 'react';
import { CircularProgress, Box, ButtonProps, Button } from '@material-ui/core';
import { classes } from 'utils';
import './styles.sass';

type Props = {
  rootClassName?: string;
  loading?: boolean;
  justifyContent?: string;
  show?: boolean;
} & ButtonProps;


export const LoadingButton: React.FC<Props> = ({ show = true, justifyContent = 'center', rootClassName = '', loading = false, children, ...props }) => {
  if (!show) {
    return null;
  }
  return (
    <Box
      display="flex"
      justifyContent={justifyContent}
      alignItems="center"
      position="relative"
      className={classes('LoadingButton', rootClassName)}
    >
      <Button className="LoadingButton__button" disabled={loading} {...props}>
        {children}
        {loading && <CircularProgress className="cirular-progress" />}
      </Button>
    </Box>
  );
};
