export const AUTH_LAYOUT_SCOPE = 'AuthLayout';
export const BUSINESS_CARD_REGISTRATION_SCOPE = 'BusinessCardRegistration';

export const uk = {
  [BUSINESS_CARD_REGISTRATION_SCOPE]: {
    Fields: {
      hasCompany: {
        label: 'register with Company',
      },
    },
    Titles: {
      'root': 'Реєстрація',
      'user': 'Користувач',
      'company': 'Компанія',
    },
  },
  scan_business_card: 'Зареєструватися за допомогою скана візитки',
  standard_registration: 'Стандартна реєстрація',
};

export const en: typeof uk = {
  [BUSINESS_CARD_REGISTRATION_SCOPE]: {
    Fields: {
      hasCompany: {
        label: 'register with Company',
      },
    },
    Titles: {
      'root': 'Registration',
      'user': 'User',
      'company': 'Company',
    },
  },
  scan_business_card: 'Register using a scanned business card',
  standard_registration: 'Standard registration',
};

export const ru: typeof uk = {
  [BUSINESS_CARD_REGISTRATION_SCOPE]: {
    Fields: {
      hasCompany: {
        label: 'зарегистрироваться c компанией',
      },
    },
    Titles: {
      'root': 'Регистрация',
      'user': 'Пользователь',
      'company': 'Компания',
    },
  },
  scan_business_card: 'Зарегистрироваться с помощью скана визитки:',
  standard_registration: 'Стандартная регистрация',
};

export const bg: typeof uk = {
  [BUSINESS_CARD_REGISTRATION_SCOPE]: {
    Fields: {
      hasCompany: {
        label: 'регистрирайте се в компанията',
      },
    },
    Titles: {
      'root': 'Регистрация',
      'user': 'Потребител',
      'company': 'Компания',
    },
  },
  scan_business_card: 'Регистрирайте се със сканирана визитка',
  standard_registration: 'Стандартна регистрация',
};
