import { API } from '../modules/Api';
import Logger from '../modules/Logger';
import {
  getNotificationsStarted,
  getNotificationsSuccess,
  getNotificationsFailure,
  loadMoreNotifications,
  getUnreadCountNotificationSuccess,
  setReadedNotifications,
} from '../actions/notificationsActions';
import { API_ROUTES } from '../constants/general';
import { infoPopupActions } from '../actions/infoPopupAction';
import { TsThunk } from 'store/store.types';
import { buildApiUrl } from './utils';
import { IApiListResponse } from 'reducers/slices/interfaces';
import { INotification } from 'reducers/notificationsReducer';

export const getNotifications = (params?: { limit?: number, offset?: number, unread?: boolean }): TsThunk => {
  const apiUrl = buildApiUrl(API_ROUTES.notifications, params);

  return (dispatch) => {
    dispatch(getNotificationsStarted());

    API.get<IApiListResponse<INotification>>(apiUrl).then(
      (response) => {
        dispatch(getNotificationsSuccess(response.data));
      },
      (error) => {
        Logger.info(error);
        dispatch(getNotificationsFailure(error.message));
      },
    );
  };
};

export const getMoreNotifications = (): TsThunk => {
  return (dispatch, getState) => {
    const linkToGetNextData = getState().notifications.next?.toString();
    if (!linkToGetNextData) {
      return;
    }

    API.get<IApiListResponse<INotification>>(linkToGetNextData).then(
      (response) => {
        dispatch(loadMoreNotifications(response.data));
      },
      (error) => {
        Logger.info(error);
        dispatch(getNotificationsFailure(error.message));
      },
    );
  };
};

export const readAllNotificationsApi = (): TsThunk<Promise<void>> => {
  return readNotificationsApi({ showPopup: true });
};

export const readNotificationsApi = ({ ids, showPopup }: { ids?: number[], showPopup?: boolean }): TsThunk<Promise<void>> => {
  return (dispatch) => {
    return API.patch<{ unread_notifications: number }>(API_ROUTES.readNotifications, { ids })
      .then(({ data: { unread_notifications } }) => {
        showPopup && dispatch(infoPopupActions.success('success'));
        dispatch(setReadedNotifications({ ids, unread_notifications }));
      })
      .catch((errorPost) => {
        Logger.info('POST_READ_NOTIFICATIONS', errorPost.response);
      });
  };
};

export const getTradeNotificationCounts = (): TsThunk => {
  return (dispatch) => {
    API.get<{ unread_notifications: number; unread_trade_message: number; }>(`${API_ROUTES.notifications}unread_count/`)
      .then((response) => {
        dispatch(getUnreadCountNotificationSuccess(response.data));
      })
      .catch((error) => {
        Logger.error(error.response);
      });
  };
};
