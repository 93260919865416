import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgClockIcon: React.FC<{ className?: string }> = ({ className }) => {

  return (
    <SvgIconContainer className={className}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path d="M30 16C30 8.26801 23.732 2 16 2" stroke="currentColor" strokeWidth="2" strokeDasharray="2 2" />
        <path d="M16 8V16.5L21 21.5" stroke="currentColor" strokeWidth="2" />
      </svg>
    </SvgIconContainer>
  );
};
