import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgProductsIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 17L13 14.6V9.8M7 17L1 14.6V9.8M7 17V12.2M13 9.8L7 12.2M13 9.8V5M13 5L7 7.4M13 5V2M1 5L7 7.4M1 5V9.8M1 5V2M1 9.8L7 12.2M7 12.2V7.4M7 7.4V0"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </SvgIconContainer>
  );
};
