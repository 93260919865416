import React, { ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { classes } from 'utils';
import './style.sass';

interface Params {
  className?: string;
  show?: boolean;
  opacity?: boolean;
  opacityMode?: boolean;
  hideContent?: boolean;
  children?: ReactNode;
  size?: number;
  fullScreen?: boolean;
}

const LoaderForData: React.FC<Params> = ({ className, show, opacityMode, children, size = 80, fullScreen, hideContent }) => {
  // return <>{!hideContent && children}</>;
  if (!show && !opacityMode) {
    return <>{!hideContent && children}</>;
  }
  return (
    <div className={classes('LoaderForData', { opacityMode, fullScreen }, className)}>
      <div className="LoaderForData__WrapIcon">
        <CircularProgress className="Icon" size={size} />
      </div>
      <div className="opacity-on">
        {!hideContent && opacityMode && children}
      </div>
    </div>
  );
};

export default LoaderForData;
