import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import LeftBg from 'assets/img/left_bg.svg';
import RightBg from 'assets/img/right_bg.svg';
import { ReactComponent as Image } from 'assets/img/404.svg';
import { Logo } from 'components/Logo';
import { ROUTES } from 'constants/routes';
import './style.sass';

export const NotFound: React.FC = () => {
  const { t: _t } = useTranslation();
  const { push } = useHistory();

  return (
    <Box className="NotFound">
      <img src={LeftBg} alt="bg" className="NotFound__bg-left"/>
      <Box className="NotFound__main">
        <Box>
          <Logo className="NotFound__logo" withText />
        </Box>
        <Box>
          <Image className="NotFound__img-404" />
        </Box>
        <Box>
          <h1 className="NotFound__title">{_t('Страница не найдена')}</h1>
        </Box>
        <Box>
          <p className="NotFound__desc">
            {_t(
              'Ой, похоже, мы не можем найти страницу, которую вы ищете. Попробуйте вернуться на предыдущую страницу или посетите наш Справочный центр для получения дополнительной информации.',
            )}
          </p>
        </Box>
        <Box>
          <Button className="NotFound__button" onClick={() => push(ROUTES.market)}>
            {_t('Перейти на главную страницу')}
          </Button>
        </Box>
      </Box>
      <img src={RightBg} alt="bg" className="NotFound__bg-right" />
    </Box>
  );
};
