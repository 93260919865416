import { queryFromUrl } from 'api/utils';
import { AnyTODO } from 'core/interfaces';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { profileCompanySelector } from 'reducers/profile/company.reducer';
import { IApiListResponseNew } from 'reducers/slices/interfaces';
import { useTsSelector } from 'store/store.types';

type Func<T = AnyTODO, A = AnyTODO> = (arg?: A) => Promise<IApiListResponseNew<T>>;
// type Awaited<T> = T extends PromiseLike<infer U> ? U : T;


export function useApiList<F extends Func>(
  action: F,
  mustHaveParams?: Parameters<F>[0],
  options?: {
    isCompanyRelated?: boolean,
  },
): [Awaited<ReturnType<F>> | undefined, boolean] {

  const [items, setItems] = useState<Awaited<ReturnType<F>>>();
  const [loading, setLoading] = useState<boolean>(true);
  const company = useTsSelector(profileCompanySelector);
  const location = useLocation();

  const queryParams = queryFromUrl(location.search);
  const needToReload = options?.isCompanyRelated && company.id;
  useEffect(() => {
    setLoading(true);
    action({ ...queryParams, ...mustHaveParams })
      .then(result => { setItems(result as Awaited<ReturnType<F>>); })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, needToReload]);

  return [items, loading];
}
