import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, API_ROUTES } from 'modules/Api';
import { RootState } from 'store/store.types';
import { FactoryBuilder, factoryInitState, factorySetAction } from '../slices/helpers';
import { IAdditionalApiStatuses } from '../slices/interfaces';
import { userIdSelector } from './user.slice';

export { useTsSelector, useTsDispatch } from 'store/store.types';

interface IUserAvatar {
  image?: string,
}

const initialData: IUserAvatar= {
  image: undefined,
};
const initialState: IUserAvatar & IAdditionalApiStatuses = factoryInitState(initialData);

export const NAME = 'avatar';
const parent = 'profile';
const scope = `${parent}/${NAME}`;
const apiUrl = (id: number): string => `${API_ROUTES.users.root}${id}/avatar/`;

export const createUserAvatar = createAsyncThunk(
  `${scope}/create`,
  async (data: IUserAvatar, thunkApi): Promise<IUserAvatar> => {
    const id = userIdSelector(thunkApi.getState() as RootState);
    if (id === null) throw Error('id not defined');
    await API.post(apiUrl(id), data);
    return data;
  },
);
export const updateUserAvatar = createAsyncThunk(
  `${scope}/update`,
  async (data: IUserAvatar, thunkApi): Promise<IUserAvatar> => {
    const id = userIdSelector(thunkApi.getState() as RootState);
    if (id === null) throw Error('id not defined');
    await API.patch(apiUrl(id), data);
    return data;
  },
);
export const removeUserAvatar = createAsyncThunk(
  `${scope}/remove`,
  async (_, thunkApi): Promise<IUserAvatar> => {
    const id = userIdSelector(thunkApi.getState() as RootState);
    if (id === null) throw Error('id not defined');
    await API.delete(apiUrl(id));
    return { image: null };
  },
);


export const {
  reducer,
  actions: {
    setUserAvatar,
    resetUserAvatar,
  },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setUserAvatar: (state, payload) => factorySetAction<IUserAvatar>(state, payload),
    resetUserAvatar: () => ({ ...initialState }),
  },
  extraReducers: builder => new FactoryBuilder(builder)
    .buildAction(createUserAvatar)
    .buildAction(removeUserAvatar)
    .buildAction(updateUserAvatar)
  ,
});

export default reducer;
