export const SCOPE_VOICE_RECORDER_FIELD = 'VoiceRecorderField';

export const uk = {
  title: 'Аудіозаписувач',
  no_media_device: 'відсутній пристрій запису',
  maximum_quantity_reached: 'досягнуто максимальної кількості',
  stop: 'остановити запис',
  start: 'включити запис',
  audio_list: 'список аудіо',
  add_audio: 'Додати аудіо',
};

export const en: typeof uk = {
  title: 'Audio Recorder',
  no_media_device: 'no media device',
  maximum_quantity_reached: 'maximum quantity reached',
  stop: 'stop recording',
  start: 'start recording',
  audio_list: 'audio list',
  add_audio: 'Add audio',
};

export const ru: typeof uk = {
  title: 'Аудио рекордер',
  no_media_device: 'отсутствует устройство записи',
  maximum_quantity_reached: 'достигнуто максимальное количество',
  stop: 'остановить запись',
  start: 'включить запись',
  audio_list: 'список аудио',
  add_audio: 'Добавить аудио',
};

export const bg: typeof uk = {
  title: 'Аудио записващо устройство',
  no_media_device: 'няма записващо устройство',
  maximum_quantity_reached: 'достигнато максимално количество',
  stop: 'спиране на записа',
  start: 'започнете запис',
  audio_list: 'аудио списък',
  add_audio: 'Добавете аудио',
};
