import store from 'store/store';
import Nav, { ROUTES } from 'nav';
import { ICompany } from 'reducers/allReducer';
import { generatePath } from 'react-router';
import { Language } from 'i18n';
import { CompanyHandshake } from 'models/CompanyHandshake';
import { ICompanyInfo } from 'reducers/slices/myCompany.slice';

export type { ICompany };
export { Nav };

export class Company {
  protected _store!: ReturnType<typeof store['getState']>;

  static find(id: string | number): Company {
    return new this(this.store.all.companies.list[id] || {});
  }

  private static get store() {
    return store.getState();
  }

  constructor (
    public $: Partial<ICompany | ICompanyInfo>,
  ) {}

  get id() { return this.$.id; }
  get name(): string { return this.$.name || ''; }
  get localName() { return this.$.local_name || this.name; }
  get abbr(): string {
    const str = (this.localName || 'NA');
    return (str.split(/[\s_!?.\-\\/]/).map(s => s[0]).join('') + str[1]).slice(0, 2);
  }

  get canShowCabinet(): boolean {
    return Boolean(this.$.can_show_cabinet || this.$.handshake && new CompanyHandshake(this.$.handshake).realValue);
  }


  get path(): string {
    // if (this.$.can_show_cabinet) {
    return generatePath(ROUTES.companies.show.goods, { id: this.$.id || 0 });
    // }
    // return `${ROUTES.companies.root}?search=${this.id}`;
  }


  goTo(e?: React.SyntheticEvent): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    Nav.go(this.path);
  }

  get fullPath(): string {
    return Language.current.fullPath(this.path);
  }

  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}
