import { AnyTODO } from 'core/interfaces';

function isValueNotToRemove(value: AnyTODO): boolean {
  switch (typeof value) {
    case 'object':
      return (Array.isArray(value) ? value : Object.keys(value || {})).length > 0;
    case 'undefined':
      return false;
    case 'string':
      return value !== '';
    default:
      return true;
  }
}


export function cleanObject(obj: AnyTODO): AnyTODO {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(cleanObject).filter(isValueNotToRemove);
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const cleanedValue = cleanObject(value);
    if (!isValueNotToRemove(cleanedValue)) {
      return acc;
    }
    acc[key] = cleanedValue;
    return acc;
  }, {} as Record<string, AnyTODO>);
}
