import { SCOPE_SIDE_BAR } from 'i18n/structure/components/SideBar';

export const SCOPE_CABINET = 'Cabinet';

export const uk = {
  title: `$t(${SCOPE_SIDE_BAR}$menu$cabinet) ({{name}})`,
  buttonBack: `$t(${SCOPE_SIDE_BAR}$menu$cabinet) ({{name}})`,
  tabs: {
    my_lots: 'Мої заявки',
    my_trades: 'Мої торги',
    my_goods: 'Мої товари',
    my_company: 'Моя компанія',
    favorite_companies: 'Вибрані компанії',
    invited_companies: 'Запрошення',
  },
};

export const en: typeof uk = {
  title: `$t(${SCOPE_SIDE_BAR}$menu$cabinet) ({{name}})`,
  buttonBack: `$t(${SCOPE_SIDE_BAR}$menu$cabinet) ({{name}})`,
  tabs: {
    my_lots: 'My requests',
    my_trades: 'My trades',
    my_goods: 'My goods',
    my_company: 'My company',
    favorite_companies: 'Favorite companies',
    invited_companies: 'Invitations',
  },
};

export const ru: typeof uk = {
  title: `$t(${SCOPE_SIDE_BAR}$menu$cabinet) ({{name}})`,
  buttonBack: `$t(${SCOPE_SIDE_BAR}$menu$cabinet) ({{name}})`,
  tabs: {
    my_lots: 'Мои заявки',
    my_trades: 'Мои торги',
    my_goods: 'Мои товары',
    my_company: 'Моя компания',
    favorite_companies: 'Избранные компании',
    invited_companies: 'Приглашения',
  },
};

export const bg: typeof uk = {
  title: `$t(${SCOPE_SIDE_BAR}$menu$cabinet) ({{name}})`,
  buttonBack: `$t(${SCOPE_SIDE_BAR}$menu$cabinet) ({{name}})`,
  tabs: {
    my_lots: 'Моите заявки',
    my_trades: 'Моите сделки',
    my_goods: 'Моите стоки',
    my_company: 'Моята компания',
    favorite_companies: 'Любими компании',
    invited_companies: 'Покани',
  },
};
