export const SCOPE_INVITED_COMPANIES = 'InvitedCompanies';

export const uk = {
  Fields: {
    name: {
      title: 'Ім\'я',
      author: 'автор',
    },
    user: {
      title: 'Адресат',
    },
    image: {
      title: 'Фото',
    },
    status: {
      title: 'Статус',
      names: {
        init: 'Створено',
        sent: 'Надіслано',
        registered: 'Використано',
      },
    },
    created_at: {
      title: 'Створено',
    },
    actions: {
      title: 'Дії',
      send: {
        title: 'Лист запрошення',
        confirm_text: 'Підтвердьте відправку запрошення на email: [{{email}}]. Лист буде сформований і надісланий автоматично.',
      },
    },
  },
  InviteCompaniesEdit: {
    title: 'Запрошення',
  },
  InvitedCompanyList: {
    title: 'Запрошення',
    buttons: {
      create_from_business_card: 'Зробити з візитки',
    },
  },
};

export const en: typeof uk = {
  Fields: {
    name: {
      title: 'Name',
      author: 'author',
    },
    user: {
      title: 'Addressee',
    },
    image: {
      title: 'Photo',
    },
    status: {
      title: 'Status',
      names: {
        init: 'Created',
        sent: 'Sent',
        registered: 'Used',
      },
    },
    created_at: {
      title: 'Created',
    },
    actions: {
      title: 'Actions',
      send: {
        title: 'Invitation letter',
        confirm_text: 'Confirm sending the invitation to email: [{{email}}]. The letter will be generated and sent automatically.',
      },
    },
  },
  InviteCompaniesEdit: {
    title: 'Invitation',
  },
  InvitedCompanyList: {
    title: 'Invitations',
    buttons: {
      create_from_business_card: 'Make from a business card',
    },
  },
};

export const ru: typeof uk = {
  Fields: {
    name: {
      title: 'Имя',
      author: 'автор',
    },
    user: {
      title: 'Адресат',
    },
    image: {
      title: 'Фото',
    },
    status: {
      title: 'Статус',
      names: {
        init: 'Создано',
        sent: 'Отправлено',
        registered: 'Использовано',
      },
    },
    created_at: {
      title: 'Создано',
    },
    actions: {
      title: 'Действия',
      send: {
        title: 'Письмо приглашение',
        confirm_text: 'Подтвердите отправку приглашения на email: [{{email}}]. Письмо будет сформировано и отправлено автоматически.',
      },
    },
  },
  InviteCompaniesEdit: {
    title: 'Приглашение',
  },
  InvitedCompanyList: {
    title: 'Приглашения',
    buttons: {
      create_from_business_card: 'Сделать из визитки',
    },
  },
};

export const bg: typeof uk = {
  Fields: {
    name: {
      title: 'Имя',
      author: 'автор',
    },
    user: {
      title: 'Адресат',
    },
    image: {
      title: 'Фото',
    },
    status: {
      title: 'Статус',
      names: {
        init: 'Created',
        sent: 'Изпратен',
        registered: 'Used',
      },
    },
    created_at: {
      title: 'Създано',
    },
    actions: {
      title: 'Действия',
      send: {
        title: 'Писмо с покана',
        confirm_text: 'Потвърдете изпращането на поканата на имейл: [{{email}}]. Писмото ще бъде генерирано и изпратено автоматично.',
      },
    },
  },
  InviteCompaniesEdit: {
    title: 'Покана',
  },
  InvitedCompanyList: {
    title: 'Покани',
    buttons: {
      create_from_business_card: 'Направете от визитка',
    },
  },
};
