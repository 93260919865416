import React from 'react';
import { classes as classesUtil } from 'utils';
import './style.sass';

interface Prosp {
  className?: string;
  classes?: string | string[];
  mirrow?: boolean;
}

const SvgIconContainer: React.FC<Prosp> = ({ className, classes, mirrow = false, children }) => (
  <div className={classesUtil('svg-icon-container', classes, className, { mirrow })}>
    {children}
  </div>
);

export default SvgIconContainer;
