import React, { ReactNode } from 'react';
import { Dialog, Box, IconButton, Button } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useT } from 'hooks';
import Notificate from 'services/notificate.service';
import { LoadingButton } from 'components/LoadingButton';
import './style.sass';

interface Props {
  show?: boolean;
  title?: string;
  children?: ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
}

export const DialogConfirm: React.FC<Props> = ({ show = true, title, children, onConfirm, onClose, loading = false }) => {
  const _t = useT();

  const hConfirm: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    Notificate.run('confirm');
    onConfirm();
  };
  const hClose: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Notificate.run('close');
    onClose();
  };

  if (!show) {
    return null;
  }
  return (
    <Dialog
      className="dialog-confirm"
      onClose={onClose}
      open={Boolean(show)}
      transitionDuration={{ enter: 200, exit: 0 }}
    >
      <Box p={4}>
        <IconButton className="close" component="span" size="small" onClick={hClose} >
          <Close/>
        </IconButton>
        <h3>{title}</h3>
        <div className="dialog-confirm__body">
          {children}
        </div>
        <Box mt={3}>
          <Box className="dialog-confirm__btns">
            <Button className="button-cancel" onClick={hClose}>
              {_t.old('Отменить')}
            </Button>
            <LoadingButton loading={loading} onClick={hConfirm}>
              {_t.old('Подтвердить')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
